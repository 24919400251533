import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const FormComponent = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
            publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }).then(
            () => {
                console.log('SUCCESS!');
            },(error) => {
                console.log(error)
                console.log('FAILED...', error);
            },
        );
    };

    return (
        
        <section class="bg-gray-50 dark:bg-gray-100">
            <div class="py-8 px-4 mx-auto max-w-screen-xl">
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8" id="reserva">
                    Reserva tu vuelo
                </h2>
                <div className='flex justify-center'>
                    <div class="w-full lg:max-w-xl space-y-8 sm:p-8 bg-white rounded-lg shadow-xl dark:bg-gray-200">
                        <form class="mt-8 space-y-6" action="#" ref={form} onSubmit={sendEmail}>
                            <div>
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-black text-left">Correo electrónico</label>
                                <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-100 dark:border-gray-100 dark:placeholder-gray-500 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required />
                            </div>
                            <div>
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-black text-left">Nombre</label>
                                <input type="text" name="full_name" id="nombre" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-100 dark:border-gray-100 dark:placeholder-gray-500 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                            </div>
                            <div>
                                <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-black text-left">Número de telefono</label>
                                <input type="tel" name="phone" id="telefono" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-100 dark:border-gray-100 dark:placeholder-gray-500 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="4234 2323" required />
                            </div>

                            <div className='grid gap-6 mb-6 md:grid-cols-2'>
                                <div>
                                    <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-black text-left">Desde</label>
                                    <select name="departure" id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-100 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option selected>Departamento</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Baja Verapaz">Baja Verapaz</option>
                                        <option value="Alta Verapaz">Alta Verapaz</option>
                                        <option value="El Progreso">El Progreso</option>
                                        <option value="Izabal">Izabal</option>
                                        <option value="Zacapa">Zacapa</option>
                                        <option value="Chiquimula">Chiquimula</option>
                                        <option value="Santa Rosa">Santa Rosa</option>
                                        <option value="Jalapa">Jalapa</option>
                                        <option value="Jutiapa">Jutiapa</option>
                                        <option value="Sacatepéquez">Sacatepéquez</option>
                                        <option value="Chimaltenango">Chimaltenango</option>
                                        <option value="Escuintla">Escuintla</option>
                                        <option value="Suchitepéquez">Suchitepéquez</option>
                                        <option value="Retalhuleu">Retalhuleu</option>
                                        <option value="San Marcos">San Marcos</option>
                                        <option value="Huehuetenango">Huehuetenango</option>
                                        <option value="Quiché">Quiché</option>
                                        <option value="Baja Verapaz">Baja Verapaz</option>
                                        <option value="Petén">Petén</option>
                                        <option value="Izabal">Izabal</option>
                                        <option value="Alta Verapaz">Alta Verapaz</option>
                                        <option value="Quetzaltenango">Quetzaltenango</option>
                                        <option value="Sololá">Sololá</option>
                                        <option value="Totonicapán">Totonicapán</option>
                                        <option value="Quiché">Quiché</option>
                                        <option value="Huehuetenango">Huehuetenango</option>
                                        <option value="San Marcos">San Marcos</option>
                                        <option value="Quetzaltenango">Quetzaltenango</option>
                                    </select>
                                </div>
                                <div>
                                    <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-black text-left">Hacia</label>
                                    <select name="arrival" id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-100 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option selected>Departamento</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Baja Verapaz">Baja Verapaz</option>
                                        <option value="Alta Verapaz">Alta Verapaz</option>
                                        <option value="El Progreso">El Progreso</option>
                                        <option value="Izabal">Izabal</option>
                                        <option value="Zacapa">Zacapa</option>
                                        <option value="Chiquimula">Chiquimula</option>
                                        <option value="Santa Rosa">Santa Rosa</option>
                                        <option value="Jalapa">Jalapa</option>
                                        <option value="Jutiapa">Jutiapa</option>
                                        <option value="Sacatepéquez">Sacatepéquez</option>
                                        <option value="Chimaltenango">Chimaltenango</option>
                                        <option value="Escuintla">Escuintla</option>
                                        <option value="Suchitepéquez">Suchitepéquez</option>
                                        <option value="Retalhuleu">Retalhuleu</option>
                                        <option value="San Marcos">San Marcos</option>
                                        <option value="Huehuetenango">Huehuetenango</option>
                                        <option value="Quiché">Quiché</option>
                                        <option value="Baja Verapaz">Baja Verapaz</option>
                                        <option value="Petén">Petén</option>
                                        <option value="Izabal">Izabal</option>
                                        <option value="Alta Verapaz">Alta Verapaz</option>
                                        <option value="Quetzaltenango">Quetzaltenango</option>
                                        <option value="Sololá">Sololá</option>
                                        <option value="Totonicapán">Totonicapán</option>
                                        <option value="Quiché">Quiché</option>
                                        <option value="Huehuetenango">Huehuetenango</option>
                                        <option value="San Marcos">San Marcos</option>
                                        <option value="Quetzaltenango">Quetzaltenango</option>
                                    </select>
                                </div>

                                <div>
                                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-black text-left">Partida</label>
                                    <input type="date" name="departure_date" id="fecha-partida" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-100 dark:border-gray-100 dark:placeholder-gray-500 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                                </div>
                                <div>
                                    <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-black text-left">Llegada</label>
                                    <input type="date" name="arrival_date" id="fecha-llegada" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-100 dark:border-gray-100 dark:placeholder-gray-500 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                                </div>
                                
                            </div>

                            <button type="submit" class="w-full px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Reservar</button>
                        
                        
                        </form>
                    </div>
                </div>
            </div>
        </section>

        
        );
    }
    
    export default FormComponent;
    