import './App.css';
import FormComponent from './components/form';


function App() {
  return (

    <div className="App">
      <nav>
        <div className="container mx-auto px-6 py-2 flex justify-between items-center bg-transparent">
            <h2 className="font-bold text-2xl lg:text-4xl">
              Aerosa
            </h2>
            <div className="hidden lg:block">
              <ul className="inline-flex">
                <li><a className="px-4 hover:text-gray-800" href="#about">Acerca de nosotros</a></li>
                <li><a className="px-4 hover:text-gray-800" href="#contacto">Contacto</a></li>
              </ul>
            </div>
          </div>
      </nav>

      <div className="pt-80">
        <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-black">Vuela más <span class="text-blue-600 dark:text-blue-700">lejos</span>, paga menos</h1>
        <p class="text-lg font-normal text-black lg:text-xl dark:text-black">Encuentra las mejores ofertas en pasajes aéreos</p>
        <a href="#reserva" class="mt-8 inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
            Reserva
            <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
          </svg>
        </a>
      </div>


      <section className="container mx-auto px-6 p-10 py-80">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-8" id="about">
          Sobre Aerosa
        </h2>
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
            <div class="grid md:grid-cols-2 gap-8">
                <div class="bg-gray-50 bg-opacity-75 border border-gray-200 dark:border-gray-200 rounded-lg p-8 md:p-12 ">
                    <h2 class="text-gray-900 dark:text-black text-3xl font-extrabold mb-2">Misión</h2>
                    <p class="text-lg font-normal text-black dark:text-black mb-4">En Aerosa, nos comprometemos a ofrecer vuelos asequibles y de calidad excepcional para nuestros clientes. Nos esforzamos por hacer que los viajes aéreos sean accesibles para todos, mientras mantenemos los más altos estándares de seguridad y comodidad.</p>
                </div>
                <div class="bg-gray-50 bg-opacity-75 border-gray-200 dark:border-gray-200 rounded-lg p-8 md:p-12">
                    <h2 class="text-gray-900 dark:text-black text-3xl font-extrabold mb-2">Historia</h2>
                    <p class="text-lg font-normal text-black dark:text-black mb-4">Aerosa fue fundada como una aerolínea y tour operador con una visión clara: ofrecer experiencias inolvidables a nuestros pasajeros. Con un equipo apasionado y comprometido, Aerosa se ha convertido en sinónimo de calidad y confiabilidad en la industria de la aviación, y estamos ansiosos por seguir creciendo y sirviendo a nuestros clientes con la misma dedicación y excelencia que nos han caracterizado desde el principio.</p>
                </div>
            </div>
        </div>
      </section>

      <FormComponent></FormComponent>

      <footer className="bg-gray-100">
        <div className="pt-14">
          <div class="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 class="text-base font-semibold uppercase tracking-wide text-blue-700" id="contacto">
                Contacto
            </h2>
          </div>
          <div class="flex items-stretch justify-center mb-4">
                <ul class="mb-6 md:mb-0">
                <div class="flex justify-between">
                  <li class="flex mr-6">
                        <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-700 text-gray-50">
                          <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                            <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                            <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                          </svg>
                        </div>
                        <div class="ml-4 mt-2">
                            <a class="text-gray-600 dark:text-slate-400" href="mailto:info@aerosaair.com">E-mail: info@aerosaair.com</a>
                        </div>
                  </li>
                </div>
                </ul>
          </div>
        </div>
        <hr></hr>

        <div className="container mx-auto px-6 pt-10 pb-6">
          <p className='mb-3'>Copyright © 2024 aerosa - todos los derechos reservados.</p>
          <p className='mb-3'>Powered by Fantastech</p>
        </div>
      </footer>



    </div>
  );
}

export default App;
